import React, { useContext, useState, useEffect } from "react";
import styles from "./Header.module.scss";
import { useNavigate, Link } from "react-router-dom";
import classnames from "classnames";

const Header = ({ no_animation, no_background }) => {
    let navigate = useNavigate(); 
    const [reachedTop, setReachedTop] = useState(true);

    useEffect(() => {
    }, []);

    window.onscroll = function() {
        if(no_animation) return;
        const isAtTop = window.pageYOffset < 10;
        if (isAtTop) {
            setReachedTop(true);
        } else {
            setReachedTop(false);
        }
    };

    const routeChange = (path) =>{ 
        navigate('/' + path);
    }

    return (
        <header>
            <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png"></link>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"></link>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"></link>
            <link rel="manifest" href="/site.webmanifest"></link>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"></link>
            <title>cuter.co</title>

            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
            <meta name="msapplication-TileColor" content="#da532c"></meta>
            <meta name="theme-color" content="#1F1F1F"></meta>
            <Link className={classnames(styles.outer_container)} style={{position: no_animation ? 'absolute' : 'fixed'}}onClick={() => this.forceUpdate()} to={"/"} href={"./"}>
                <div className={classnames(styles.inner_container, {[styles.black] : !no_background}, {[reachedTop ? styles.animate_down : styles.animate_up] : !no_animation})}> 
                    <div className={classnames(styles.img_container)}>
                        <img src="https://i.imgur.com/PZGU75c.png"/>
                    </div>
                    <div className={classnames(styles.cuter_text)}>
                        <p className={classnames(styles.vertical_center)}>CUTER.CO</p>
                    </div>
                </div>
            </Link>
        </header>
    );
};

export default Header;