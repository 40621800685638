import React, { useContext, useRef, useState, useEffect } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useNavigate, Link } from "react-router-dom";
import { useStickyBox } from "react-sticky-box";
import { useLocation  } from "react-router";
import { AspectRatio } from 'react-aspect-ratio';
import { Helmet, HelmetProvider } from "react-helmet-async";
import FlatList from 'flatlist-react';
import FadeIn from 'react-fade-in';
import moment from 'moment';
import Ratio from 'react-ratio';
import AppContext from '../../contexts/AppContext';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "../../components/Image";
import styles from "./Profile.module.scss";
import { getPaymentLink, createCheckoutSession } from "../../services/api";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51N2lCNBd6wB4GZO9tj56rJuiKTOIZ0hk7Xd9iB1jkQojifzbUZ7st5k3e1tkfjIWOLmVY1UPBofBzeIMnDDYKlG900aECh17Og');

const users = [
    {
        email: "26cubies@gmail.com",
        username: "test",
        customerId: "cus_QmzHwRIewUGBmN",
    }
]

const style = {
    fontFamily: 'Monospace, serif'
}

const GoalModule = () => {
    return(
        <div className={classnames(styles.goal_module)}>
            <p style={style}>🚀 Money towards buying a new tablet!</p>
            <div className={classnames(styles.progress_bar_container)}>
              <div className={classnames(styles.progress_bar)} style={{ width: `42%` }}>
                <span style={style}>4%</span>
              </div>
            </div>
        </div>
    )
}

const Profile = () => {
  const navigate = useNavigate(); 
  const location = useLocation();
  const [user, setUser] = useState({

  });

    useEffect(() => {
      window.scrollTo(0, 0);
      getPayment();
      const paths = window.location.pathname;
      setUser(users[0])
    }, [location])

    const getPayment = async() => {
        var response = await getPaymentLink("userId", 10);
        console.log(response)
    }

  const handleClick = async () => {
    const stripe = await stripePromise;

    // placeholder customerId
    const response = await createCheckoutSession('cus_Qn0YieFnI2PfHD');
    console.log(response);

    // Redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: response.id,
    });

    if (result.error) {
      // Handle any errors that occur during the redirect
      console.error(result.error);
    }
  };

    return (
        <>
            <div>
                <Header/>
            </div>
            <body style={{backgroundColor: '#BBC1F2'}}>           
                <HelmetProvider>
                    <Helmet>
                        <title>{"cuter.co"}</title>
                    </Helmet>
                </HelmetProvider>
                <div className={classnames(styles.outer_container)}>
                    <div className={classnames(styles.profile_container)}>
                        <div className={classnames(styles.banner)} style={{backgroundColor: '#505FEC'}}>
                        </div>
                        <div className={classnames(styles.inner_container)}>

                            <div className={classnames(styles.profile)}>
                                <img src="https://i.imgur.com/Lj3E6sv.png"/>
                            </div>
                            <h1 style={style}>Jane Doe</h1>
                            <p style={style}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                    </div>
                    <div className={classnames(styles.module)}>
                        <h2 style={style}>Donate to User so they can buy art supplies!</h2>

                        <button style={{display: 'block', margin: 'auto'}} onClick={handleClick}>
                          Donate
                        </button>

                        <h2>Goals 🤩</h2>

                        <GoalModule>

                        </GoalModule>
                    </div>
                </div>
            </body>
            <div>
                <Footer/>
            </div>
        </>
    )
};

export default Profile;
