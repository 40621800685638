function startSession(response) {
    const userInfo = { response };
    console.log(userInfo)
    localStorage.setItem('user', userInfo);
    return userInfo;
}

export {
    startSession
};
