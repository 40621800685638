import React, { useContext, useCallback, useRef, useState, useEffect } from "react";
import classnames from "classnames";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router";
import { AspectRatio } from 'react-aspect-ratio';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import FlatList from 'flatlist-react';
import FadeIn from 'react-fade-in';
import axios from 'axios';
import moment from 'moment';
import Ratio from 'react-ratio';
import AppContext from '../../contexts/AppContext';

import { startSession } from "../../services/utils";
import { onboardConnectAccount, createCustomer, createStripeExpressAccount } from '../../services/api';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "../../components/Image";
import styles from "./Signup.module.scss";

const Signup = () => {
  const navigate = useNavigate(); 
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [onboardingWindow, setOnboardingWindow] = useState(false);
  const [state, setState] = useState({
    onboardingLink: null,
  });
  const [account, setAccount] = useState({
    accountId: null,
    customerId: null,
  });
    useEffect(() => {
      window.scrollTo(0, 0);
      console.log(location)
    }, [location]);

    useEffect(() => {
        let checkWindowClosed;
        if (onboardingWindow) {
          checkWindowClosed = setInterval(() => {
            if (onboardingWindow.closed) {
              clearInterval(checkWindowClosed);
            }
          }, 1000);
        }

        return () => {
          if (checkWindowClosed) {
            clearInterval(checkWindowClosed);
          }
        };
    }, [onboardingWindow]);

    const createProfile = async() => {
        const res = await createStripeExpressAccount(location.state.email, 'US');
        const customer =  await createCustomer(location.state.email);
        console.log(customer.customerId)
        console.log(res.accountId)
        setAccount({
            customerId: customer.customerId,
            accountId: res.accountId
        });
        setPage(2);
    }

    const googleLogin = useGoogleLogin({
      onSuccess: async ({ code }) => {
        console.log(code)
        const response = await axios.post('http://localhost:5000/api/v1/auth/google', {  // http://localhost:3001/auth/google backend that will exchange the code
          code,
        });
        startSession(response.data)
      },
      flow: 'auth-code',
    });

    const openOnboardingLink = async() => {
        console.log(account)
        // if(!account.accountId) {// make account;}
        const redirectLink = await onboardConnectAccount(account.accountId);
        const windowSize = 'width=600, height=700';
        const newOnboardingWindow = window.open(redirectLink, 'stripe-onboarding', windowSize);
        setOnboardingWindow(newOnboardingWindow);
    }

    return (
        <>
            <div>
                <Header no_animation no_background/>
            </div>
            <body style={{backgroundColor: '#131313'}}>           
                <HelmetProvider>
                    <Helmet>
                        <title>{"cuter.co"}</title>
                    </Helmet>
                </HelmetProvider>
                <div className={classnames(styles.wrapper)}>
                    <p>🙌 Finish creating your profile</p>
                {page === 1 ?
                    <div className={classnames(styles.outer_container, styles.one_col)}>
                        {/*<div className={classnames(styles.profile_container)}>
                            <div className={classnames(styles.banner)} style={{backgroundColor: '#505FEC'}}>
                            </div>
                            <div className={classnames(styles.inner_container)}>

                                <div className={classnames(styles.profile)}>
                                    <img src="https://i.imgur.com/Lj3E6sv.png"/>
                                </div>
                                <h1>Jane Doe</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>*/}
                        <div className={classnames(styles.module)}>
                            <p>(Display) Name:*</p>
                            <input type="text" placeholder="Jazzy Pineapple"></input>
                            <p>Username:*</p>
                            <input type="text" placeholder="@jazzypineapple"></input>
                            <p>About:</p>
                            <textarea placeholder="Hello world!" rows="3"></textarea>
                            <p>Links:</p>
                            <input type="text" placeholder="https://"></input>
                            <input type="text" placeholder="https://"></input>
                            <input type="text" placeholder="https://"></input>
                            <a onClick={createProfile} className={classnames(styles.button)}>Next</a>
                        </div>
                    </div>
                    : page === 2 ?
                    <div className={classnames(styles.outer_container, styles.two_col_a)}>
                        <div className={classnames(styles.module)}>
                            <p>Theme</p>
                            <p>Secondary theme</p>
                            <p>Font</p>
                            <a onClick={() => setPage(3)} className={classnames(styles.button)}>Next</a>
                        </div>
                        <div className={classnames(styles.profile_container)}>
                            <div className={classnames(styles.banner)} style={{backgroundColor: '#505FEC'}}>
                            </div>
                            <div className={classnames(styles.inner_container)}>

                                <div className={classnames(styles.profile)}>
                                    <img src="https://i.imgur.com/Lj3E6sv.png"/>
                                </div>
                                <h1>Jane Doe</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={classnames(styles.outer_container, styles.two_col_b)}>
                        <div className={classnames(styles.profile_container)}>
                            <div className={classnames(styles.banner)} style={{backgroundColor: '#505FEC'}}>
                            </div>
                            <div className={classnames(styles.inner_container)}>

                                <div className={classnames(styles.profile)}>
                                    <img src="https://i.imgur.com/Lj3E6sv.png"/>
                                </div>
                                <h1>Jane Doe</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>
                        <div className={classnames(styles.module)}>
                            <p>Connect Stripe</p>
                            <a onClick={() => openOnboardingLink()} href="javascript:void(0)" className={classnames(styles.button)}>Sign up as a vendor to start receiving money</a>

                        </div>
                    </div>
                }
                </div>
            </body>
            <div>
                <Footer/>
            </div>
        </>
    )
};

export default Signup;
