import React, { useContext, useCallback, useRef, useState, useEffect } from "react";
import classnames from "classnames";
import { useNavigate, Link } from "react-router-dom";
import { useLocation  } from "react-router";
import { AspectRatio } from 'react-aspect-ratio';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import FlatList from 'flatlist-react';
import FadeIn from 'react-fade-in';
import axios from 'axios';
import moment from 'moment';
import Ratio from 'react-ratio';
import AppContext from '../../contexts/AppContext';

import { startSession } from "../../services/utils";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Image from "../../components/Image";
import styles from "./Home.module.scss";

const TypingEffect = ({ words, typingSpeed = 70, typingDelay = 700 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  const currentWord = words[wordIndex % words.length];

  const typeCharacter = useCallback(() => {
    if (isTyping) {
      setDisplayedText(prev => {
        if (prev.length < currentWord.length) {
          return currentWord.slice(0, prev.length + 1);
        } else {
          setIsTyping(false);
          return prev;
        }
      });
    } else {
      setDisplayedText(prev => {
        if (prev.length > 0) {
          return prev.slice(0, -1);
        } else {
          setIsTyping(true);
          setWordIndex(prevIndex => prevIndex + 1);
          return '';
        }
      });
    }
  }, [currentWord, isTyping]);

  useEffect(() => {
    const timer = setTimeout(() => {
      typeCharacter();
    }, isTyping ? typingSpeed : (displayedText.length === 0 ? typingDelay : typingSpeed));

    return () => clearTimeout(timer);
  }, [displayedText, isTyping, typeCharacter, typingSpeed, typingDelay]);

  return <div className={classnames(styles.dynamic_text_container)}><span>{"Alexa is"}<br/>{displayedText}</span></div>;
};

const Home = () => {
  const navigate = useNavigate(); 
  const location = useLocation();
  const [user, setUser] = useState(null);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    const googleLogin = useGoogleLogin({
      onSuccess: async ({ code }) => {
        console.log(code)
        const response = await axios.post('http://localhost:5000/api/v1/auth/google', {  // http://localhost:3001/auth/google backend that will exchange the code
          code,
        });
        //Todo: Reject response if applicable
        if(response.data.success) {
            startSession(response.data);
            navigate('/signup', { state: response.data });
        }
      },
      flow: 'auth-code',
    });

    const errorMessage = (error) => {
        console.log(error);
    };

    return (
        <>
            <div>
                <Header no_animation no_background/>
            </div>
            <body style={{backgroundColor: '#131313'}}>           
                <HelmetProvider>
                    <Helmet>
                        <title>{"cuter.co"}</title>
                    </Helmet>
                </HelmetProvider>
                <div className={classnames(styles.outer_container)}>
                    <div className={classnames(styles.module, styles.vertical_center)}>
                        <p>🤗 Support your favorite creators</p>
                        <p>Get paid instantly, no fees, just donations 💸</p>
                        <div className={classnames(styles.horizontal_line)}/>
                        <div style={{display: 'flex', justifyContent: 'right'}}>
                            
                            <div onClick={() => googleLogin()} className={classnames(styles.sign_in_button)}>Sign in with Google</div>
                        </div>
                    </div>
                    <div className={classnames(styles.profile_container)}>
                        <div className={classnames(styles.banner)} style={{backgroundColor: '#505FEC'}}>
                        </div>
                        <div className={classnames(styles.inner_container)}>
                            <div className={classnames(styles.profile)}>
                                <img src="https://i.imgur.com/Lj3E6sv.png"/>
                            </div>
                            <div>
                                <TypingEffect words={ ["creating art", "selling jewelry", "making pottery", "filming a video"]} typingSpeed={100} typingDelay={800}/>
                            </div>
                            <a className={classnames(styles.button)} style={{backgroundColor: '#505FEC'}}>Donate</a>
                        </div>
                    </div>
                </div>
            </body>
            <div>
                <Footer/>
            </div>
        </>
    )
};

export default Home;
