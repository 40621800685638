import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';

import AppContext from './contexts/AppContext';

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import Privacy from "./pages/Privacy";

const App = () => {
  const url = window.location;
  const pathname = window.location.pathname

  useEffect(() => {
  }, []);

  window.addEventListener("pageshow", (event) => {
  const historyTraversal =
    event.persisted ||
    (typeof window.performance != "undefined" &&
      window.performance.navigation.type === 2);

  if (historyTraversal) {
    window.location.reload();
  }
});

  return (
    <AppContext.Provider value="">
        <BrowserRouter className="App">
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/@" element={<Profile />} />
          </Routes>
        </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
