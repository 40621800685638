const BASE = "http://localhost:5000/api/v1/";

var HEADER = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
});

function getPaymentLink(userId, amount) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            userId: userId,
            amount: amount
        })
    };
    return fetch(BASE + "create-payment-link", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function createCustomer(email) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            email: email
        })
    };
    return fetch(BASE + "create-customer", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function createCheckoutSession(customerId) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            customerId: customerId
        })
    };
    return fetch(BASE + "create-checkout-session", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}


function createStripeExpressAccount(email, country) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            email: email,
            country: country
        })
    };
    return fetch(BASE + "create-connect-account", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

function onboardConnectAccount(accountId) {
    const requestOptions = {
        method: 'POST',
        headers: HEADER,
        redirect: 'follow',
        body: JSON.stringify({
            accountId: accountId
        })
    };
    return fetch(BASE + "onboard-user", requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson
        })
        .catch((error) => {
            return { err: error };
        });
}

export {
    createCheckoutSession,
    createCustomer,
    createStripeExpressAccount,
    getPaymentLink,
    onboardConnectAccount
};
